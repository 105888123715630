// context.tsx
import { createContext, useState, type Dispatch, type SetStateAction } from "react";

import type { Tenant } from "@prisma/client";
import type { User } from "@clerk/clerk-sdk-node";

export interface AppContextData {
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  user: (User & Tenant) | null;
  setUser: Dispatch<SetStateAction<(User & Tenant) | null>>;
}

const AppContext = createContext<AppContextData | null>(null);

function AppContextProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState<(User & Tenant) | null>(null);

  return (
    <AppContext.Provider
      value={{
        isSubmitting,
        setIsSubmitting,
        isEditing,
        setIsEditing,
        user,
        setUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContextProvider };
export default AppContext;
